import Parse from 'parse';
import i18n from '../i18n';
import AppLog from './AppLog';

export const LANG_CODE_LOCAL = 'Local';
export const LANG_CODES = [LANG_CODE_LOCAL, 'En', 'Zh', 'Ja', 'Ko', 'Fr', 'De'];
export const LANG_CODES_DESC = ['lang_code_local', 'lang_code_en', 'lang_code_zh', 'lang_code_ja', 'lang_code_ko', 'lang_code_fr', 'lang_code_de'];

const APP_ID = 'Lqnnakqmmsv3rpntf55AarYgytdgdfuPsguegYej';
const JAVASCRIPT_KEY = 'jzwwqki5ftauhiryhwfdyj3kHqwERM65fJjzrdnk';
//const SERVER_URL = 'https://gozity.atominvention.com/parse';
const SERVER_URL = 'https://gozitydev.atominvention.com/parse';

const RANDOM_PICK_CHARACTERS = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
  'u', 'v', 'w', 'x', 'y', 'z',
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
  'U', 'V', 'W', 'X', 'Y', 'Z',
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

const RANDOM_SORT_FIELD = [
  'objectId', 'publishTime', 'createdAt'
];

class ParseUtil {

  constructor() {
    this._resetUserState();
    this._init();
  }

  _resetUserState() {
    this.userParseObject = null;
    this.isParseUserAdmin = false;
  }

  getUser() {
    return this.userParseObject;
  }

  isUserAdmin() {
    return this.isParseUserAdmin;
  }

  async logout() {
    try {
      await Parse.User.logOut();
    } catch (e) {
      AppLog.log(e);
    }
    this._resetUserState();
  }

  _init() {
    Parse.initialize(APP_ID, JAVASCRIPT_KEY);
    Parse.serverURL = SERVER_URL;
    Parse.User.enableUnsafeCurrentUser();
  }

  _getEventParseObject() {
    return Parse.Object.extend('TripsEvent');
  }

  _getCateParseObject() {
    return Parse.Object.extend('TripsCate');
  }

  _getGeoPointParseObject() {
    return Parse.Object.extend('TripsGeoPoint');
  }

  _getFileParseObject() {
    return Parse.Object.extend('TripsEventFile');
  }

  _getCityLocationParseObject() {
    return Parse.Object.extend('TripsCityLocation');
  }

  _getCountryLocationParseObject() {
    return Parse.Object.extend('TripsCountryLocation');
  }

  _getProvinceLocationParseObject() {
    return Parse.Object.extend('TripsProvinceLocation');
  }

  _getAreaLocationParseObject() {
    return Parse.Object.extend('TripsAreaLocation');
  }

  _getTripsUserTripPlanObject() {
    return Parse.Object.extend('TripsUserTripPlan');
  }

  _getTripsUserPublicDataObject() {
    return Parse.Object.extend('TripsUserPublicData');
  }

  _getUserFavWebsiteObject() {
    return Parse.Object.extend('TripsUserFavoriteWebSite');
  }

  _getFavWebsiteObject() {
    return Parse.Object.extend('TripsFavoriteWebSite');
  }

  _getUserFavEventObject() {
    return Parse.Object.extend('TripsUserFavoriteEvent');
  }

  _getEventPendingParseObject() {
    return Parse.Object.extend('TripsEventPending');
  }

  _getGeoPointPendingParseObject() {
    return Parse.Object.extend('TripsGeoPointPending');
  }

  _getFilePendingParseObject() {
    return Parse.Object.extend('TripsEventFilePending');
  }

  _getAdsParseObject() {
    return Parse.Object.extend('TripsAds');
  }

  _getAdsPendingParseObject() {
    return Parse.Object.extend('TripsAdsPending');
  }

  async login(username, password) {
    try {
      this.userParseObject = await Parse.User.logIn(username, password, {});
      this.isParseUserAdmin = await this.checkUserIsAdminRole();
      return this.userParseObject;
    } catch (e) {
      return null;
    }
  }

  async checkLocalSessionAndLogin() {
    try {
      let currentUser = Parse.User.current();
      await currentUser?.fetch();
      if (currentUser) {
        this.userParseObject = currentUser;
        this.isParseUserAdmin = await this.checkUserIsAdminRole();
        return true;
      }
    } catch (e) {
      AppLog.log(e);
    }
    return false;
  }

  async checkUserIsAdminRole() {
    return await this.checkUserIsRole('Admin');
  }

  async checkUserIsPublisherRole() {
    return await this.checkUserIsRole('Publisher');
  }

  async isUserSelfPublisher() {
    let isAdmin = await this.checkUserIsAdminRole();
    let isPublisher = await this.checkUserIsPublisherRole();
    return !isAdmin && !isPublisher;
  }

  async checkUserIsRole(roleName) {
    try {
      let query = new Parse.Query(Parse.Role);
      query.equalTo('name', roleName);
      query.equalTo('users', this.userParseObject);
      let result = await query.first();
      return result != null;
    } catch (e) {
      return false;
    }
  }

  async getCurrentUser() {
    return await Parse.User.currentAsync();
  }

  /**
  * logout user
  * @return {Promise<void>}
  */
  async logoutUser() {
    await Parse.User.logOut();
  }

  async getUserPointer() {
    let currentUserObject = await this.getCurrentUser();
    return {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': currentUserObject.id
    };
  }

  getNameForCurrentLocale(object, fieldName) {
    let langCode = LANG_CODES[1];
    let lang = i18n.language;
    if (lang.indexOf('zh') === 0) {
      langCode = LANG_CODES[2];
    }
    let value = object.get(fieldName + langCode);
    if (!value) {
      value = object.get(fieldName + LANG_CODE_LOCAL);
    }
    if (!value) {
      // still no value, pick whatever language
      for (let i = 0; i < LANG_CODES.length; i++) {
        let langCode = LANG_CODES[i];
        value = object.get(fieldName + langCode);
        if (value) {
          break;
        }
      }
    }
    return value;
  }

  getSortFieldForCurrentLocale(fieldName) {
    let langCode = LANG_CODES[1];
    let lang = i18n.language;
    if (lang.indexOf('zh') === 0) {
      langCode = LANG_CODES[2];
    }
    return fieldName + langCode;
  }

  getNameValuesForDisplay(object, fieldName, langCodes) {
    let names = '';
    for (let j = 0; j < langCodes.length; j++) {
      let langCode = langCodes[j];
      let nameValue = object.get(fieldName + langCode);
      if (nameValue) {
        if (names.length > 0) {
          names += '<br/>';
        }
        names = names + '<b>' + langCode + ':</b> ' + nameValue;
      }
    }
    return names;
  }

  async getAllArea() {
    let query = new Parse.Query(this._getAreaLocationParseObject());
    query.include('countryLocation');
    query.include('geoPoint');
    query.descending('createdAt');
    query.limit(1000);
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAreas(countryId) {
    let query = new Parse.Query(this._getAreaLocationParseObject());
    query.limit(1000);
    query.ascending(this.getSortFieldForCurrentLocale('name'));

    let countryLocation = this._getCountryLocationParseObject();
    let countryLocationObject = new countryLocation();
    countryLocationObject.id = countryId;
    query.equalTo('countryLocation', countryLocationObject);

    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAllCountry() {
    let query = new Parse.Query(this._getCountryLocationParseObject());
    query.descending('createdAt');
    query.limit(1000);
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async saveArea(names, countryId, lat, lng, areaId) {
    let area = this._getAreaLocationParseObject();
    let areaObject;
    if (areaId) {
      areaObject = await this.getArea(areaId);
    } else {
      areaObject = new area();
    }

    let keys = Object.keys(names);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      areaObject.set(key, names[key]);
    }
    areaObject.set('geoPoint', this._getNewParseGeoPointObject(lat, lng));

    let country = this._getCountryLocationParseObject();
    let countryObject = new country();
    countryObject.id = countryId;
    areaObject.set('countryLocation', countryObject);

    try {
      return await areaObject.save();
    } catch (e) {
      return null;
    }
  }

  _getNewParseGeoPointObject(lat, lng) {
    return new Parse.GeoPoint({
      latitude: Number(lat),
      longitude: Number(lng)
    });
  }

  async getArea(areaId) {
    let query = new Parse.Query(this._getAreaLocationParseObject());
    query.include('countryLocation');
    query.include('geoPoint');
    query.descending('createdAt');
    query.equalTo('objectId', areaId);
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async getAllProvinces(limit, skip) {
    let query = new Parse.Query(this._getProvinceLocationParseObject());
    query.include('countryLocation');
    query.include('areaLocation');
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getProvince(provinceId) {
    let query = new Parse.Query(this._getProvinceLocationParseObject());
    query.equalTo('objectId', provinceId);
    query.include('areaLocation');
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async getProvinces(areaId) {
    let query = new Parse.Query(this._getProvinceLocationParseObject());
    query.limit(1000);
    query.ascending(this.getSortFieldForCurrentLocale('name'));

    let areaLocation = this._getAreaLocationParseObject();
    let areaLocationObject = new areaLocation();
    areaLocationObject.id = areaId;
    query.equalTo('areaLocation', areaLocationObject);

    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async saveProvince(names, countryId, areaId, provinceId) {
    let province = this._getProvinceLocationParseObject();
    let provinceObject;
    if (provinceId) {
      provinceObject = await this.getProvince(provinceId);
    } else {
      provinceObject = new province();
    }

    let keys = Object.keys(names);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      provinceObject.set(key, names[key]);
    }

    let country = this._getCountryLocationParseObject();
    let countryObject = new country();
    countryObject.id = countryId;
    provinceObject.set('countryLocation', countryObject);

    let area = this._getAreaLocationParseObject();
    let areaObject = new area();
    areaObject.id = areaId;
    provinceObject.set('areaLocation', areaObject);

    try {
      return await provinceObject.save();
    } catch (e) {
      return null;
    }
  }

  async getAllCategory() {
    let query = new Parse.Query(this._getCateParseObject());
    query.ascending('order');
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getCategory(cateId) {
    let query = new Parse.Query(this._getCateParseObject());
    query.equalTo('objectId', cateId);
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async saveCategory(names, order, fileName, base64, cateId) {
    let parseFile;
    if (base64) {
      let uploadFileName = 'cateIcon.jpg';
      if (fileName) {
        uploadFileName = 'cateIcon.' + fileName.split('.').pop();
      }
      parseFile = new Parse.File(uploadFileName, {base64: base64});
      try {
        await parseFile.save();
      } catch (e) {
        return null;
      }
    }
    let cate = this._getCateParseObject();
    let cateObject;
    if (cateId) {
      cateObject = await this.getCategory(cateId);
    } else {
      cateObject = new cate();
    }

    let keys = Object.keys(names);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      cateObject.set(key, names[key]);
    }
    if (parseFile) {
      cateObject.set('icon', parseFile);
    }
    cateObject.set('order', order);

    try {
      return await cateObject.save();
    } catch (e) {
      return null;
    }
  }

  async getEventsForNames(eventNames, excludeEventId) {
    let result = await this.getEventsForNamesResult(eventNames, false, excludeEventId);
    let pendingResult = await this.getEventsForNamesResult(eventNames, true, excludeEventId);

    if (!result) {
      return pendingResult;
    } else if (!pendingResult) {
      return result;
    } else {
      let finalResult = [];
      for (let i = 0; i < result.length; i++) {
        finalResult.push(result[i]);
      }
      for (let i = 0; i < pendingResult.length; i++) {
        finalResult.push(pendingResult[i]);
      }
      return finalResult;
    }
  }

  async getEventsForNamesResult(eventNames, isPending, excludeEventId) {
    let nameQueries = [];
    let keys = Object.keys(eventNames);
    for (let i = 0; i < keys.length; i++) {
      let eventParseObject;
      if (isPending) {
        eventParseObject = this._getEventPendingParseObject();
      } else {
        eventParseObject = this._getEventParseObject();
      }
      let key = keys[i];
      let query = new Parse.Query(eventParseObject);
      let value = eventNames[key];
      if (!value || value.length <= 0) {
        continue;
      }
      query.contains(key, value);
      nameQueries.push(query);
    }
    let query = Parse.Query.or(...nameQueries);
    if (excludeEventId) {
      query.notEqualTo('objectId', excludeEventId);
    }
    query.limit(10);
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  assignObjectValueFromEventInfo(eventObject, keyValues) {
    let keys = Object.keys(keyValues);
    keys.forEach((item) => {
      let value = keyValues[item].trim();
      eventObject.set(item, value);
    });
  }

  setAclForUserSubmit(object, currentUser) {
    let parseAcl = new Parse.ACL(currentUser);
    parseAcl.setRoleWriteAccess('Admin', true);
    parseAcl.setRoleReadAccess('Admin', true);
    // if user does not have role (i.e. self reg), then don't allow publisher to read her data
    if (!this.isUserSelfPublisher()) {
      parseAcl.setRoleReadAccess('Publisher', true);
    }
    object.setACL(parseAcl, {});
  }

  async getTripsGeoPointPendingObject(lat, lng) {
    let parseGeoPoint = new Parse.GeoPoint({
      latitude: Number(lat),
      longitude: Number(lng)
    });
    let tripsGeoPoint = this._getGeoPointPendingParseObject();
    let geoPointObject = new tripsGeoPoint();
    geoPointObject.set('location', parseGeoPoint);
    this.setAclForUserSubmit(geoPointObject, await this.getCurrentUser());
    return geoPointObject;
  }

  async getTripsGeoPointObject(lat, lng) {
    let parseGeoPoint = new Parse.GeoPoint({
      latitude: Number(lat),
      longitude: Number(lng)
    });
    let tripsGeoPoint = this._getGeoPointParseObject();
    let geoPointObject = new tripsGeoPoint();
    geoPointObject.set('location', parseGeoPoint);
    return geoPointObject;
  }

  async saveAds(adsInfo) {
    AppLog.log(`Save ads`);
    AppLog.log(adsInfo);

    let adsPending = this._getAdsPendingParseObject();
    let adsObject;

    let country = this._getCountryLocationParseObject();
    let countryObject = new country();
    countryObject.id = adsInfo.countryId;

    let uploadFileName = 'ads.jpg';
    let parseFile = new Parse.File(uploadFileName, {base64: adsInfo.imageBase64});
    try {
      await parseFile.save();
    } catch (e) {
      return null;
    }

    adsObject = new adsPending();
    adsObject.set('countryLocation', countryObject);
    adsObject.set('link', adsInfo.link);
    adsObject.set('title', adsInfo.title);
    adsObject.set('description', adsInfo.description);
    adsObject.set('image', parseFile);
    adsObject.set('createdBy', await this.getUserPointer());

    let ownerUser = await this.getCurrentUser();
    this.setAclForUserSubmit(adsObject, ownerUser);

    try {
      return await adsObject.save();
    } catch (e) {
      return null;
    }
  }

  async saveEvent(eventInfo, editEventId, isEditPendingEvent) {
    AppLog.log(`Save event, editEventId: ${editEventId}, isEditPendingEvent: ${isEditPendingEvent}`);
    AppLog.log(eventInfo);

    let tripsEventPending = this._getEventPendingParseObject();
    let eventObject;

    if (editEventId) {
      // edit event
      if (isEditPendingEvent) {
        // edit pending
        eventObject = await this.getPendingEvent(editEventId);
      } else {
        // edit current event
        eventObject = await this.getEvent(editEventId);
      }
    } else {
      // add new event
      eventObject = new tripsEventPending();
      // set event type 1: self publish event 0: normal event
      eventObject.set('eventType', await this.isUserSelfPublisher() ? 1 : 0);
    }

    let ownerUser = await this.getCurrentUser();
    if (editEventId) {
      ownerUser = eventObject.get('createdBy');
    }

    // event name, addresses and event desc
    this.assignObjectValueFromEventInfo(eventObject, eventInfo.eventNames);
    this.assignObjectValueFromEventInfo(eventObject, eventInfo.startAddressValues);
    this.assignObjectValueFromEventInfo(eventObject, eventInfo.endAddressValues);
    this.assignObjectValueFromEventInfo(eventObject, eventInfo.eventDescValues);

    // lat lng
    let startLat = eventInfo.startAddressLat;
    let startLng = eventInfo.startAddressLng;
    let endLat = eventInfo.endAddressLat;
    let endLng = eventInfo.endAddressLng;

    // province
    let province = this._getProvinceLocationParseObject();
    let provinceObject = new province();
    provinceObject.id = eventInfo.provinceId;
    eventObject.set('provinceLocation', provinceObject);

    // date and time
    eventObject.set('eventStartDate', eventInfo.startDate);
    eventObject.set('eventEndDate', eventInfo.endDate);
    eventObject.set('eventStartTime', Number(eventInfo.startTime));
    eventObject.set('eventEndTime', Number(eventInfo.endTime));
    eventObject.set('minStayTime', Number(eventInfo.minStayTime));
    eventObject.set('maxStayTime', Number(eventInfo.maxStayTime));

    // content info
    eventObject.set('isIndoor', Number(eventInfo.indoor));
    eventObject.set('isIncludeMeal', Number(eventInfo.includeMeal));
    eventObject.set('stamina', Number(eventInfo.stamina));
    eventObject.set('bestSeason', Number(eventInfo.season));
    eventObject.set('wikiLink', eventInfo.wiki ? eventInfo.wiki.trim() : '');
    eventObject.set('instagramPostLink', eventInfo.instagramPost ? eventInfo.instagramPost.trim() : '');

    // links, we only use local field
    eventObject.set('linksLocal', eventInfo.eventLinks);

    // clear disapprove reason after editing
    eventObject.set('disapproveReason', '');

    // category
    let cateObjects = [];
    for (let item of eventInfo.categoryIds) {
      let cate = this._getCateParseObject();
      let cateObject = new cate();
      cateObject.id = item;
      cateObjects.push(cateObject);
    }
    eventObject.set('cates', cateObjects);

    // geo point
    if (editEventId) {
      // edit current geo point
      let startLocation = eventObject.get('startLocation');
      let endLocation = eventObject.get('endLocation');
      let startGeoPoint = new Parse.GeoPoint({
        latitude: Number(startLat),
        longitude: Number(startLng)
      });
      let endGeoPoint = new Parse.GeoPoint({
        latitude: Number(endLat),
        longitude: Number(endLng)
      });
      startLocation.set('location', startGeoPoint);
      endLocation.set('location', endGeoPoint);
    } else {
      // save new geo point
      let startGeoPoint = await this.getTripsGeoPointPendingObject(startLat, startLng);
      let endGeoPoint = await this.getTripsGeoPointPendingObject(endLat, endLng);
      eventObject.set('startLocation', await startGeoPoint.save());
      eventObject.set('endLocation', await endGeoPoint.save());
    }

    // other not required fields, preserve for edit event
    if (!editEventId) {
      eventObject.set('minSpend', -1);
      eventObject.set('maxSpend', -1);
      eventObject.set('viewCount', 0);
      eventObject.set('createdBy', await this.getUserPointer());
    }

    this.setAclForUserSubmit(eventObject, ownerUser);

    // finally save the event
    let parseEventObject = await eventObject.save();

    // edit files
    if (editEventId && (!eventInfo.editPhotos || eventInfo.editPhotos.length <= 0)) {
      // remove all existing photos
      let files;
      if (isEditPendingEvent) {
        files = await this.getEventPendingFiles(editEventId);
      } else {
        files = await this.getEventFiles(editEventId);
      }
      await Parse.Object.destroyAll(files, {});
    }

    if (eventInfo.base64Images) {
      // save new files
      let parseFiles = [];
      for (let i = 0; i < eventInfo.base64Images.length; i++) {
        let parseFile = new Parse.File('eventImage' + i, {base64: eventInfo.base64Images[i]});
        parseFiles.push(parseFile);
      }

      let fileResults = await Parse.Object.saveAll(parseFiles, {});
      let eventFiles = [];
      fileResults.forEach((item, index, array) => {
        let event = this._getEventPendingParseObject();
        if (editEventId && !isEditPendingEvent) {
          event = this._getEventParseObject();
        }
        let eventObject = new event();
        eventObject.id = parseEventObject.id;

        let eventFile = this._getFilePendingParseObject();
        if (editEventId && !isEditPendingEvent) {
          eventFile = this._getFileParseObject();
        }
        let eventFileObject = new eventFile();
        eventFileObject.set('order', index);
        eventFileObject.set('image', item);
        eventFileObject.set('copyright', '');
        eventFileObject.set('event', eventObject);
        this.setAclForUserSubmit(eventFileObject, ownerUser);
        eventFiles.push(eventFileObject);
      });
      await Parse.Object.saveAll(eventFiles, {});
    }

    return parseEventObject;
  }

  async sendApprovalEmail(targetUserId, eventId, isApproved, disapproveReason) {
    await Parse.Cloud.run('SendEventApprovalEmail', {
      targetUserId,
      eventId,
      isApproved,
      disapproveReason
    });
  }

  async addDisapproveReason(pendingEvent, reason) {
    pendingEvent.set('disapproveReason', reason);
    await pendingEvent.save();
  }

  async addDisapproveReasonForAds(pendingAds, reason) {
    pendingAds.set('disapproveReason', reason);
    await pendingAds.save();
  }

  async approveAds(pendingAds) {
    // move event from pending to normal event
    return await this.copyAdsForApproval(pendingAds);
  }

  async copyAdsForApproval(sourceAdsObject) {
    let adsObject = this._getAdsParseObject();
    let destAdsObject = new adsObject();
    destAdsObject.set('countryLocation', sourceAdsObject.get('countryLocation'));
    destAdsObject.set('link', sourceAdsObject.get('link'));
    destAdsObject.set('title', sourceAdsObject.get('title'));
    destAdsObject.set('description', sourceAdsObject.get('description'));
    destAdsObject.set('image', sourceAdsObject.get('image'));
    destAdsObject.set('createdBy', sourceAdsObject.get('createdBy'));

    let savedObject = await destAdsObject.save();
    this.deletePendingAds(sourceAdsObject.id);

    return savedObject;
  }

  async disapproveEvent(approvedEvent) {
    // move event from normal event to pending
    return await this.copyEventForApproval(approvedEvent, true);
  }

  async approveEvent(pendingEvent) {
    // move event from pending to normal event
    return await this.copyEventForApproval(pendingEvent);
  }

  async copyEventForApproval(sourceEventObject, isDisapprove) {
    let destTripsEvent = isDisapprove ? this._getEventPendingParseObject() : this._getEventParseObject();
    let destEventObject = new destTripsEvent();

    // event name and addresses
    LANG_CODES.forEach((item, index) => {
      let nameField = 'eventName' + item;
      destEventObject.set(nameField, sourceEventObject.get(nameField));
      let startAddressField = 'startAddress' + item;
      destEventObject.set(startAddressField, sourceEventObject.get(startAddressField));
      let endAddressField = 'endAddress' + item;
      destEventObject.set(endAddressField, sourceEventObject.get(endAddressField));
      let eventDescField = 'eventDesc' + item;
      destEventObject.set(eventDescField, sourceEventObject.get(eventDescField));
    });

    // lat lng
    let sourceStartGeoPoint = sourceEventObject.get('startLocation').get('location');
    let sourceEndGeoPoint = sourceEventObject.get('endLocation').get('location');
    let destStartGeoPoint;
    let destEndGeoPoint;
    if (isDisapprove) {
      destStartGeoPoint = await this.getTripsGeoPointPendingObject(sourceStartGeoPoint.latitude, sourceStartGeoPoint.longitude);
      destEndGeoPoint = await this.getTripsGeoPointPendingObject(sourceEndGeoPoint.latitude, sourceEndGeoPoint.longitude);
    } else {
      destStartGeoPoint = await this.getTripsGeoPointObject(sourceStartGeoPoint.latitude, sourceStartGeoPoint.longitude);
      destEndGeoPoint = await this.getTripsGeoPointObject(sourceEndGeoPoint.latitude, sourceEndGeoPoint.longitude);
    }

    // province
    let province = this._getProvinceLocationParseObject();
    let provinceObject = new province();
    provinceObject.id = sourceEventObject.get('provinceLocation').id;
    destEventObject.set('provinceLocation', provinceObject);

    // date and time
    destEventObject.set('eventStartDate', sourceEventObject.get('eventStartDate'));
    destEventObject.set('eventEndDate', sourceEventObject.get('eventEndDate'));
    destEventObject.set('eventStartTime', sourceEventObject.get('eventStartTime'));
    destEventObject.set('eventEndTime', sourceEventObject.get('eventEndTime'));
    destEventObject.set('minStayTime', sourceEventObject.get('minStayTime'));
    destEventObject.set('maxStayTime', sourceEventObject.get('maxStayTime'));

    // content info
    destEventObject.set('isIndoor', sourceEventObject.get('isIndoor'));
    destEventObject.set('isIncludeMeal', sourceEventObject.get('isIncludeMeal'));
    destEventObject.set('stamina', sourceEventObject.get('stamina'));
    destEventObject.set('bestSeason', sourceEventObject.get('bestSeason'));
    destEventObject.set('wikiLink', sourceEventObject.get('wikiLink'));
    destEventObject.set('instagramPostLink', sourceEventObject.get('instagramPostLink'));

    // only use local for links
    destEventObject.set('linksLocal', sourceEventObject.get('linksLocal'));

    // category
    destEventObject.set('cates', sourceEventObject.get('cates'));

    // geo point
    destEventObject.set('startLocation', await destStartGeoPoint.save());
    destEventObject.set('endLocation', await destEndGeoPoint.save());

    // other not required fields
    destEventObject.set('minSpend', sourceEventObject.get('minSpend'));
    destEventObject.set('maxSpend', sourceEventObject.get('maxSpend'));
    destEventObject.set('viewCount', sourceEventObject.get('viewCount'));

    destEventObject.set('createdBy', sourceEventObject.get('createdBy'));
    destEventObject.set('eventType', sourceEventObject.get('eventType'));

    // data all ready, can save the target object now
    let destParseEventObject = await destEventObject.save();

    // copy the source attached files to target object
    let files = isDisapprove ? await this.getEventFiles(sourceEventObject.id) :
      await this.getEventPendingFiles(sourceEventObject.id);

    let destEventFiles = [];
    for (let i = 0; i < files.length; i++) {
      let event = isDisapprove ? this._getEventPendingParseObject() : this._getEventParseObject();
      let eventObject = new event();
      eventObject.id = destParseEventObject.id;

      let pendingFile = files[i];
      let eventFile = isDisapprove ? this._getFilePendingParseObject() : this._getFileParseObject();
      let eventFileObject = new eventFile();
      eventFileObject.set('order', i);
      eventFileObject.set('image', pendingFile.get('image'));
      eventFileObject.set('copyright', '');
      eventFileObject.set('event', eventObject);
      destEventFiles.push(eventFileObject);
    }
    await Parse.Object.saveAll(destEventFiles, {});

    // remove source items
    if (isDisapprove) {
      if (this.isUserAdmin()) {
        this.deleteEvent(sourceEventObject.id);
      } else {
        // we need to call cloud code to delete the source event because we do not have permission
        await this.deleteEventForUserByCloudCode(sourceEventObject.id);
      }
    } else {
      this.deletePendingEvent(sourceEventObject.id);
    }

    return destParseEventObject;
  }

  async deleteEventForUserByCloudCode(eventId) {
    await Parse.Cloud.run('deleteEventForUser', {eventId: eventId});
  }

  async getAllEvent(limit, skip, displayAll) {
    let query = new Parse.Query(this._getEventParseObject());
    query.include('cityLocation');
    query.include('provinceLocation');
    query.include('provinceLocation.areaLocation');
    if (!displayAll) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAllPendingEvent(limit, skip, displayAll, eventType) {
    let query = new Parse.Query(this._getEventPendingParseObject());
    query.include('cityLocation');
    query.include('provinceLocation');
    query.include('provinceLocation.areaLocation');
    if (!displayAll) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    if (eventType) {
      query.equalTo('eventType', eventType);
    }
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAllPendingAdsForUser(limit, skip, userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getAdsPendingParseObject());
    query.include('countryLocation');
    query.equalTo('createdBy', userPointer);
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAllPendingEventForUser(limit, skip, userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getEventPendingParseObject());
    query.include('cityLocation');
    query.include('provinceLocation');
    query.include('provinceLocation.areaLocation');
    query.equalTo('createdBy', userPointer);
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getEvent(eventId) {
    let query = new Parse.Query(this._getEventParseObject());
    query.equalTo('objectId', eventId);
    query.include('startLocation');
    query.include('endLocation');
    query.include('provinceLocation');
    query.include('provinceLocation.areaLocation');
    query.include('provinceLocation.countryLocation');
    query.include('cates');
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async getPendingEvent(pendingEventId) {
    let query = new Parse.Query(this._getEventPendingParseObject());
    query.equalTo('objectId', pendingEventId);
    query.include('startLocation');
    query.include('endLocation');
    query.include('provinceLocation');
    query.include('provinceLocation.areaLocation');
    query.include('provinceLocation.countryLocation');
    query.include('cates');
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async getEventFiles(eventId) {
    let event = this._getEventParseObject();
    let eventObject = new event();
    eventObject.id = eventId;

    let query = new Parse.Query(this._getFileParseObject());
    query.equalTo('event', eventObject);
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getEventPendingFiles(pendingEventId) {
    let pendingEvent = this._getEventPendingParseObject();
    let pendingEventObject = new pendingEvent();
    pendingEventObject.id = pendingEventId;

    let query = new Parse.Query(this._getFilePendingParseObject());
    query.equalTo('event', pendingEventObject);
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async deleteEvent(eventId) {
    let event = await this.getEvent(eventId);
    if (event) {
      let startLocation = event.get('startLocation');
      if (startLocation) {
        await startLocation.destroy();
      }
      let endLocation = event.get('endLocation');
      if (endLocation) {
        await endLocation.destroy();
      }
      await event.destroy();

      let files = await this.getEventFiles(eventId);
      await Parse.Object.destroyAll(files, {});
    }
  }

  async deletePendingEvent(pendingEventId) {
    let event = await this.getPendingEvent(pendingEventId);
    if (event) {
      let startLocation = event.get('startLocation');
      if (startLocation) {
        await startLocation.destroy();
      }
      let endLocation = event.get('endLocation');
      if (endLocation) {
        await endLocation.destroy();
      }
      await event.destroy();

      let files = await this.getEventPendingFiles(pendingEventId);
      await Parse.Object.destroyAll(files, {});
    }
  }

  async getProvinceCount() {
    let query = new Parse.Query(this._getProvinceLocationParseObject());
    return await query.count();
  }

  async getPendingAds(pendingAdsId) {
    let query = new Parse.Query(this._getAdsPendingParseObject());
    query.equalTo('objectId', pendingAdsId);
    query.include('countryLocation');
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async getAds(adsId) {
    let query = new Parse.Query(this._getAdsParseObject());
    query.equalTo('objectId', adsId);
    query.include('countryLocation');
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  async deleteAdsForUserByCloudCode(adsId) {
    await Parse.Cloud.run('deleteAdsForUser', {adsId: adsId});
  }

  async deletePendingAds(pendingAdsId) {
    let ads = await this.getPendingAds(pendingAdsId);
    if (ads) {
      await ads.destroy();
    }
  }

  async deleteAds(adsId) {
    let ads = await this.getAds(adsId);
    if (ads) {
      await ads.destroy();
    }
  }

  async getAllAds(limit, skip, displayAll) {
    let query = new Parse.Query(this._getAdsParseObject());
    query.include('countryLocation');
    if (!displayAll) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAllPendingAds(limit, skip, displayAll) {
    let query = new Parse.Query(this._getAdsPendingParseObject());
    query.include('countryLocation');
    if (!displayAll) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    query.descending('createdAt');
    query.limit(limit);
    if (skip > 0) {
      query.skip(skip);
    }
    try {
      return await query.find();
    } catch (e) {
      return null;
    }
  }

  async getAdsCount(isByUserOnly) {
    let query = new Parse.Query(this._getAdsParseObject());
    if (isByUserOnly) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    try {
      return await query.count();
    } catch (e) {
      // count permission denied
      return -1;
    }
  }

  async getAdsPendingCount(isByUserOnly) {
    let query = new Parse.Query(this._getAdsPendingParseObject());
    if (isByUserOnly) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    try {
      return await query.count();
    } catch (e) {
      // count permission denied
      return -1;
    }
  }

  async getEventPendingCount(isByUserOnly) {
    let query = new Parse.Query(this._getEventPendingParseObject());
    if (isByUserOnly) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    return await query.count();
  }

  async getEventPendingCountForSelfPublisher() {
    let query = new Parse.Query(this._getEventPendingParseObject());
    query.equalTo('eventType', 1);
    return await query.count();
  }

  async getAdsPendingCountForUser(userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getAdsPendingParseObject());
    query.equalTo('createdBy', userPointer);
    return await query.count();
  }

  async getEventPendingCountForUser(userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getEventPendingParseObject());
    query.equalTo('createdBy', userPointer);
    return await query.count();
  }

  async getEventCount(isByUserOnly) {
    let query = new Parse.Query(this._getEventParseObject());
    if (isByUserOnly) {
      query.equalTo('createdBy', await this.getUserPointer());
    }
    try {
      return await query.count();
    } catch (e) {
      // count permission denied
      return -1;
    }
  }

  async getAdsCountForUser(userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getAdsParseObject());
    query.equalTo('createdBy', userPointer);
    return await query.count();
  }

  async getEventCountForUser(userId) {
    let userPointer = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getEventParseObject());
    query.equalTo('createdBy', userPointer);
    return await query.count();
  }

  async getUserPublicData(userId) {
    let user = {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': userId
    };
    let query = new Parse.Query(this._getTripsUserPublicDataObject());
    query.equalTo('user', user);
    try {
      return await query.first();
    } catch (e) {
      return null;
    }
  }

  /**
  * get trips user public data from server
  * @return {Promise<*>}
  * @private
  */
  async _getTripsUserPublicData() {
    let tripsUserPublicData = this._getTripsUserPublicDataObject();
    let query = new Parse.Query(tripsUserPublicData);
    query.equalTo('user', await this._getUserPointer());
    return await query.find({});
  }

  /**
   * get a user pointer for current user
   * @return {Promise<{__type: string, className: string, objectId: *}>}
   * @private
   */
  async _getUserPointer() {
    let currentUserObject = await this.getCurrentUser();
    return {
      '__type': 'Pointer',
      'className': '_User',
      'objectId': currentUserObject.id
    };
  }

  /**
   * link user with various service such as Google and Facebook
   * @param service service
   * @param authData auth data for the service
   * @param displayName display name of the user
   * @param avatarBase64Image avatar base64 image
   * @return {Promise<*>}
   */
  async linkUser(service, authData, displayName, avatarBase64Image) {
    // link user account
    let currentUser = await this.getCurrentUser();
    if (currentUser) {
      // logout any current user before login new one
      await this.logoutUser();
    }

    let user = new Parse.User();
    let parseUser = await user.linkWith(service, authData);
    parseUser.setACL(new Parse.ACL(parseUser), {});
    await parseUser.save();

    // update avatar image
    let avatarImageFile;
    if (avatarBase64Image) {
      avatarImageFile = new Parse.File('avatar.jpg', {base64: avatarBase64Image});
    }

    // save user info to public data table
    let tripsUserPublicDataObject;
    let findResults = await this._getTripsUserPublicData();

    if (findResults && findResults.length > 0) {
      // item already uploaded
      tripsUserPublicDataObject = findResults[0];
    }
    if (!tripsUserPublicDataObject) {
      let tripsUserPublicData = this._getTripsUserPublicDataObject();
      tripsUserPublicDataObject = new tripsUserPublicData();
      tripsUserPublicDataObject.set('user', await this._getUserPointer());
      let acl = new Parse.ACL(await this.getCurrentUser());
      acl.setPublicReadAccess(true);
      tripsUserPublicDataObject.setACL(acl, {});
    }
    if (!tripsUserPublicDataObject.get('displayName')) {
      // add display name
      tripsUserPublicDataObject.set('displayName', displayName);
    }
    if (!tripsUserPublicDataObject.get('avatarImage') && avatarBase64Image) {
      // add avatar image
      tripsUserPublicDataObject.set('avatarImage', avatarImageFile);
    }
    await tripsUserPublicDataObject.save();

    return {
      user: parseUser,
      publicData: tripsUserPublicDataObject
    };
  }

  async refreshLoginForLinkUser(linkResponse) {
    try {
      this.userParseObject = linkResponse.user;
      this.isParseUserAdmin = await this.checkUserIsAdminRole();
      return this.userParseObject;
    } catch (e) {
      return null;
    }
  }

  async setUserEmail(email) {
    let user = await this.getCurrentUser();
    user.set('email', email);
    await user.save();
  }

  async refreshUser() {
    await this.userParseObject.fetch();
  }

}

let _instance = new ParseUtil();

export default _instance;
