import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import AppLog from '../util/AppLog';
import EventBus from '../util/EventBus';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import LoadingDialog from './LoadingDialog';

class AdsPreviewView extends Component {

  constructor(props) {
    super(props);
    this.state = {isShowLoadingDialog: false};
  }

  render() {
    return (
      <div>
        <div style={styles.container}>
          {this.renderAdsContentPreview()}
          <br />
          <br />
          {this.renderCloseBtn()}
          <br />
          <br />
          <br />
          {this.renderDisapprovalBtn()}
          <br />
          <br />
          <br />
          {this.renderApprovalBtn()}
          <br />
          <br />
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
      </div>
    );
  }

  async componentDidMount() {
    AppLog.log('pendingAdsId: ' + this.props.pendingAdsId);
    AppLog.log('adsId: ' + this.props.adsId);
    this.setStateAsync({isShowLoadingDialog: true});
    if (this.props.pendingAdsId) {
      await this.loadPendingAdsData();
    } else if (this.props.adsId) {
      await this.loadAdsData();
    }
    this.setStateAsync({isShowLoadingDialog: false});
  }

  async loadPendingAdsData() {
    let result = await ParseUtil.getPendingAds(this.props.pendingAdsId);
    this.setState({pendingAdsData: result});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async loadAdsData() {
    let result = await ParseUtil.getAds(this.props.adsId);
    this.setState({adsData: result});
  }

  getData() {
    let data;
    if (this.state.pendingAdsData) {
      data = this.state.pendingAdsData;
    } else if (this.state.adsData) {
      data = this.state.adsData;
    }
    return data;
  }

  renderCloseBtn() {
    const {t} = this.props;
    return (
      this.renderSubmitButton(t('ads_preview_close'), () => {
        if (this.props.onCloseClick) {
          this.props.onCloseClick();
        }
      })
    );
  }

  renderDisapprovalBtn() {
    if (this.props.viewOnly) {
      return;
    }
    const {t} = this.props;
    return (
      <div>
        <TextField style={styles.disapproveReasonTextField}
          id={'disapprove_reason'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.disapproveReason || ''}
          multiline
          onChange={event => {
            let value = event.target.value;
            this.setState({disapproveReason: value});
          }}
          label={t('ads_preview_disapprove_reason')} />
        {this.renderSubmitButton(t('ads_preview_disapprove'), () => {
          this.disapproveAds();
        }, 'delete', '#ff0000')}
      </div>
    );
  }

  async disapproveAds() {
    let reason = this.state.disapproveReason?.trim();
    if (!reason) {
      return;
    }
    const {t} = this.props;
    await this.setStateAsync({isShowLoadingDialog: true});
    await ParseUtil.addDisapproveReasonForAds(this.state.pendingAdsData, reason);
    // we don't send email anymore
    // await ParseUtil.sendApprovalEmail(this.state.pendingAdsData.get('createdBy').id,
    //   this.state.pendingAdsData.id, false, reason);
    await this.setStateAsync({isShowLoadingDialog: false});
    EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
    if (this.props.onApproved) {
      this.props.onApproved();
    }
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  renderApprovalBtn() {
    if (this.props.viewOnly) {
      return;
    }
    const {t} = this.props;
    return (
      this.renderSubmitButton(t('ads_preview_approve'), () => {
        this.approveAds();
      }, 'secondary')
    );
  }

  async approveAds() {
    const {t} = this.props;
    await this.setStateAsync({isShowLoadingDialog: true});
    await ParseUtil.approveAds(this.state.pendingAdsData);
    // we don't send email anymore
    // await ParseUtil.sendApprovalEmail(this.state.pendingAdsData.get('createdBy').id,
    //   this.state.pendingAdsData.id, true);
    await this.setStateAsync({isShowLoadingDialog: false});
    EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
    if (this.props.onApproved) {
      this.props.onApproved();
    }
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  renderAdsContentPreview() {
    let data = this.getData();
    if (!data) {
      return;
    }
    const {t} = this.props;

    let countryLocation = data.get('countryLocation');
    let country = ParseUtil.getNameValuesForDisplay(countryLocation, 'name', LANG_CODES);
    let adsLink = data.get('link');
    let image = data.get('image');
    let imageUrl = image ? image.url() : '';
    let title = data.get('title');
    let description = data.get('description');
    let createDateAt = data.get('createdAt');
    let NinetyDaysMillis = 90 * 24 * 60 * 60 * 1000;
    let expiryTimeMillis = new Date(createDateAt).getTime() + NinetyDaysMillis;
    let expiryDate = new Date(expiryTimeMillis);

    return (
      <div>
        <img style={styles.coverImage} src={imageUrl} />
        <br /><br />
        <span style={styles.description} dangerouslySetInnerHTML={{__html: title}} />
        <br /><br />
        <span style={styles.description} dangerouslySetInnerHTML={{__html: description}} />
        <br /><br />
        <a href={adsLink} target='_blank' rel="noopener noreferrer">
          <span dangerouslySetInnerHTML={{__html: adsLink}} />
        </a>
        <br /><br />
        <span dangerouslySetInnerHTML={{__html: country}} />
        <br /><br />
      </div>
    );
  }

  renderSubmitButton(text, submitFunction, color, backgroundColor) {
    let style = styles.submitBtn;
    if (backgroundColor) {
      style = {...style, backgroundColor: backgroundColor};
    }
    return (
      <Button
        style={style}
        variant="contained"
        color={color || 'primary'}
        onClick={submitFunction}>
        <div style={styles.submitBtnContent}>
          {text}
        </div>
      </Button>
    );
  }
}

const styles = {
  container: {
    padding: 8
  },
  title: {
    marginLeft: 8
  },
  coverImage: {
    width: '100%',
    maxWidth: 600,
    resizeMode: 'cover',
    marginTop: 8,
  },
  listItemInfo: {
    color: '#3984ff',
    marginLeft: 4
  },
  contentItemInfo: {
    color: '#27c3b0',
    marginLeft: 4
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  disapproveReasonTextField: {
    width: '100%',
    marginBottom: 8
  },
  description: {
    whiteSpace: 'pre-line'
  }
};

export default withTranslation()(AdsPreviewView);